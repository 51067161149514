
import { CityState, StateFederation } from "@/services/api/ApiUser";
import { ICity, IStateFederation } from "@/services/models";
import { IRegionalStoreRequest } from "@/services/models/regionals";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { GetCepData } from "@/services/api/ApiCep";
import { RegionalsStore } from "@/services/api/ApiRegionals";
import { notificationSuccess } from "@/utils/NotificationUtils";
@Component({
  validations: {
    form: {
      name: { required },
      street_name: { required },
      building_number: { required },
      post_code: { required, minLength: minLength(9), maxLength: maxLength(9) },
      neighborhood: { required },
      city_id: { required },
      state_id: { required }
    }
  }
})
export default class RegionalForm extends Vue {
  loading = false;
  @Prop() open_modal!: boolean;
  @Prop() regional_id!: number;
  form: IRegionalStoreRequest = {};
  state_list: IStateFederation[] = [];
  cities_list: ICity[] = [];

  @Watch("form.post_code")
  async cepChangeHandle() {
    if (this.form.post_code?.length == 9) {
      try {
        const cepData = await GetCepData(this.form.post_code);
        this.form = {
          ...this.form,
          street_name: cepData?.street,
          neighborhood: cepData?.neighborhood
        };
      } catch (error) {
        console.log(error);
      }
    }
  }

  @Watch("open_modal", { deep: true })
  async loadData(): Promise<void> {
    if (this.open_modal) {
      await Promise.all([this.listStates()]);
    } else {
      this.form = {};
    }
  }

  async listStates(): Promise<void> {
    const states = await StateFederation();
    this.state_list = states;
  }

  async changeState(id: number): Promise<void> {
    const cities = await CityState(id);
    this.cities_list = cities;
  }

  close(): void {
    this.$emit("close_modal");
    this.$v.$reset();
    this.form = {};
  }

  async save(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        const response = await RegionalsStore({
          ...this.form,
          post_code: this.form.post_code?.replace("-", "")
        });
        notificationSuccess(
          `${this.$t("users.messages.success.message.create")}`
        );
        this.close();
        this.$emit("reload_page");
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }
  }
}
