
import {
  IRegional,
  IRegionalListRequest
} from "@/services/models/regionals/index";
import { Component, Vue, Watch } from "vue-property-decorator";
import { RegionalsList } from "@/services/api/ApiRegionals";
import RegionalForm from "./regional-form/ReginalForm.vue";

@Component({
  components: {
    RegionalForm
  }
})
export default class Regionals extends Vue {
  loading = false;
  openModal = false;
  items: IRegional[] = [];
  perPage = 10;
  currentPage = 1;
  totalRows = 0;
  pages = 1;
  filter = "";
  fields: { key: string; label: string }[] = [
    { key: "id", label: `${this.$t("regionals.fields.id")}` },
    { key: "name", label: `${this.$t("regionals.fields.name")}` },
    { key: "actions", label: `${this.$t("regionals.fields.actions")}` }
  ];
  pageOptions = [10, 25, 50, 100];

  @Watch("perPage")
  async onPerPageChange() {
    await this.list();
  }
  @Watch("currentPage")
  async onCurrentPageChange() {
    await this.list();
  }

  showModal() {
    this.openModal = true;
  }

  closeModal() {
    this.openModal = false;
  }

  async mounted() {
    await this.list();
  }

  async list(): Promise<void> {
    this.loading = true;
    try {
      const response: IRegionalListRequest = await RegionalsList(
        this.currentPage,
        this.perPage
      );
      this.items = response.data;
      this.totalRows = response.total;
      this.pages = response.last_page;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
}
